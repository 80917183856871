import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
	Card,
	Table,
	Stack,
	Avatar,
	Button,
	Checkbox,
	TableRow,
	TableBody,
	TableCell,
	Container,
	Typography,
	TableContainer,
	TablePagination
} from '@mui/material';

import Label from './shared/Label';
import Scrollbar from './shared/Scrollbar';
import SearchNotFound from './shared/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from './userlist/index';

const TABLE_HEAD = [
	{ id: 'firstName', label: 'Имя', alignRight: false },
	{ id: 'lastName', label: 'Фамилия', alignRight: false },
	{ id: 'username', label: 'Никнейм ТГ', alignRight: false },
	{ id: 'role', label: 'Роль', alignRight: false },
	{ id: 'isBlocked', label: 'Статус', alignRight: false },
	{ id: 'isConfirmed', label: 'Подтвержден', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}
  
function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}
  
function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(array, (_user) => {
			const fullName = `${_user.firstName ?? ''} ${_user.lastName ?? ''}`;
			return fullName.toLowerCase().indexOf(query.toLowerCase()) !== -1 || (_user.username ?? '').toLowerCase().indexOf(query.toLowerCase()) !== -1;
		});
	}
	return stabilizedThis.map((el) => el[0]);
}

const blockUsers = (userIds) => {
	return fetch('/account/blockusers', {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ userIds: userIds })
	})
		.then(r => r.json())
		.then(r => {
			if (r.success) {
				alert('Пользователи успешно заблокированы!');
			} else {
				alert('Ошибка...');
			}
			return userIds;
		})
		.catch(() => {
			alert('Ошибка...');
		});
};

const unblockUsers = (userIds) => {
	return fetch('/account/unblockusers', {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ userIds: userIds })
	})
		.then(r => r.json())
		.then(r => {
			if (r.success) {
				alert('Пользователи успешно разблокированы!');
			} else {
				alert('Ошибка...');
			}
			return userIds;
		})
		.catch(() => {
			alert('Ошибка...');
		});
};

export default function UserList(props) {
	const userlist = props.users;
	const updateUsersState = props.updateUsersState;

	const [page, setPage] = useState(0);
	const [order, setOrder] = useState('asc');
	const [selected, setSelectedImpl] = useState([]);
	const [orderBy, setOrderBy] = useState('name');
	const [filterName, setFilterName] = useState('');
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const setSelected = (newSelected) => {
		props.selectedUpdated(newSelected);
		setSelectedImpl(newSelected);
	};

	const handleRequestSort = (event, property) => {
	  const isAsc = orderBy === property && order === 'asc';
	  setOrder(isAsc ? 'desc' : 'asc');
	  setOrderBy(property);
	};
  
	const handleSelectAllClick = (event) => {
	  if (event.target.checked) {
		const newSelecteds = userlist.map((n) => n.id);
		setSelected(newSelecteds);
		return;
	  }
	  setSelected([]);
	};
  
	const handleClick = (event, id) => {
	  const selectedIndex = selected.indexOf(id);
	  let newSelected = [];
	  if (selectedIndex === -1) {
		newSelected = newSelected.concat(selected, id);
	  } else if (selectedIndex === 0) {
		newSelected = newSelected.concat(selected.slice(1));
	  } else if (selectedIndex === selected.length - 1) {
		newSelected = newSelected.concat(selected.slice(0, -1));
	  } else if (selectedIndex > 0) {
		newSelected = newSelected.concat(
		  selected.slice(0, selectedIndex),
		  selected.slice(selectedIndex + 1)
		);
	  }
	  setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
	  setPage(newPage);
	};
  
	const handleChangeRowsPerPage = (event) => {
	  setRowsPerPage(parseInt(event.target.value, 10));
	  setPage(0);
	};
  
	const handleFilterByName = (event) => {
	  setFilterName(event.target.value);
	};

	const getRoleTitle = (role) => {
		if (role === 0) return 'Пользователь';
		else if (role === 1) return 'Администратор';
		else if (role === 2) return 'Владелец';
	}

	const block = () => {
		blockUsers(selected)
			.then(userIds => {
				updateUsersState(userIds, true);
			});
	};

	const unblock = () => {
		unblockUsers(selected)
			.then(userIds => {
				updateUsersState(userIds, false);
			});
	};

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userlist.length) : 0;
  
	const filteredUsers = applySortFilter(userlist, getComparator(order, orderBy), filterName);
  
	const isUserNotFound = filteredUsers.length === 0;

	return <Card style={{ maxHeight: '50%' }}>
          <UserListToolbar
            numSelected={selected.length}
			canBlock={selected.every(u =>  !userlist.find(user => user.id === u)?.isBlocked)}
			canUnblock={selected.every(u => userlist.find(user => user.id === u)?.isBlocked)}
			usersCount={userlist.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
			blockUsers={block}
			unblockUsers={unblock}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={userlist.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, firstName, lastName, username, role, isBlocked, isConfirmed } = row;
                      const isItemSelected = selected.indexOf(id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, id)}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {firstName}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {lastName}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{username}</TableCell>
                          <TableCell align="left">{getRoleTitle(role)}</TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(isBlocked && 'error') || 'success'}
                            >
                              {isBlocked ? 'Заблокирован' : 'Активен'}
                            </Label>
                          </TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(!isConfirmed && 'error') || 'success'}
                            >
                              {isConfirmed ? 'Подтвержден' : 'Не подтвержден'}
                            </Label>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={userlist.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
			title='На странице'
          />
        </Card>
}