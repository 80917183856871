import { useFormik, Form, FormikProvider } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
// material

import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';

import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

export default function LoginForm() {
	
	const [showPassword, setShowPassword] = React.useState(false);

	const LoginSchema = Yup.object().shape({
	  email: Yup.string().required('Персональный номер обязателен'),
	  password: Yup.string().required('Пароль обязателен')
	});
  
	const formik = useFormik({
	  initialValues: {
		email: '',
		password: '',
		remember: true
	  },
	  validationSchema: LoginSchema,
	  onSubmit: (values) => {
		return fetch('/account/login', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(values) })
			.then(response => response.json())
			.then(data => data.success ? window.location.reload() : alert('Ошибка'));
	  }
	});
  
	const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  
	const handleShowPassword = () => {
	  setShowPassword((show) => !show);
	};

	return (
		<FormikProvider value={formik}>
		  <Form autoComplete="off" noValidate onSubmit={e => { handleSubmit(e); formik.setSubmitting(false); }}>
			<Stack spacing={3}>
			  <TextField
				fullWidth
				autoComplete="username"
				type="email"
				label="Персональный номер"
				{...getFieldProps('email')}
				error={Boolean(touched.email && errors.email)}
				helperText={touched.email && errors.email}
			  />
	
			  <TextField
				fullWidth
				autoComplete="current-password"
				type={showPassword ? 'text' : 'password'}
				label="Пароль"
				{...getFieldProps('password')}
				InputProps={{
				  endAdornment: (
					<InputAdornment position="end">
					  <IconButton onClick={handleShowPassword} edge="end">
						<Icon icon={showPassword ? eyeFill : eyeOffFill} />
					  </IconButton>
					</InputAdornment>
				  )
				}}
				error={Boolean(touched.password && errors.password)}
				helperText={touched.password && errors.password}
			  />
	
			<LoadingButton
				fullWidth
				size="large"
				type="submit"
				variant="contained"
				loading={isSubmitting}
			>
				Login
			</LoadingButton>
			</Stack>
		  </Form>
		</FormikProvider>
	  );
}