import React, { Component } from 'react';
import { AppBar, Button, Container, Toolbar, Typography } from '@mui/material';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
        <div>
          <AppBar>
            <Toolbar>
              <Typography sx={{ flexGrow: 1 }}>
                Фильтр новостей
              </Typography>
              <Button color="inherit" onClick={this.logout}>Выйти</Button>
            </Toolbar>
          </AppBar>
          <Container>
            {this.props.children}
          </Container>
        </div>
    );
  }

  async logout() {
    await fetch('/account/logout');
    window.location.reload();
  }
}
