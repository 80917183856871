import { TextField, Stack, Button } from "@mui/material";
import UserList from "./UserList";
import { useState } from 'react';

export default function BroadcastForm(props) {
	const [message, setMessage] = useState('');
	const [selected, setSelected] = useState([]);
	const sendMessage = () => {
		fetch('/broadcast/sendtousers', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ message: message, userIds: selected })
		})
			.then(r => r.json())
			.then(r => {
				if (r.success) {
					alert('Рассылка успешно отправлена!');
					setMessage('');
				} else {
					alert('Ошибка...');
				}
			})
			.catch(() => {
				alert('Ошибка...');
			});
	};

	return (<Stack>
		<UserList users={props.users} selectedUpdated={setSelected} updateUsersState={props.updateUsersState} />
		<TextField
			style={{ backgroundColor: 'white', marginTop: '20px', marginBottom: '20px' }}
			fullWidth
			multiline
			rows={10}
			value={message}
			onChange={e => setMessage(e.target.value)}
		/>
		<Button onClick={sendMessage}>Отправить</Button>
	</Stack>);
}