import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment
} from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  //justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

export default function UserListToolbar({ numSelected, filterName, onFilterName, usersCount, canBlock, canUnblock, blockUsers, unblockUsers }) {
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      <SearchStyle
        value={filterName}
        onChange={onFilterName}
        placeholder="Найти пользователя..."
        startAdornment={
          <InputAdornment position="start">
            <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
          </InputAdornment>
        }
      />
      {numSelected > 0 && <>
        <Typography component="div" variant="subtitle1" style={{ marginLeft: '15px' }}>
          Выбрано {numSelected}{numSelected === usersCount && ' (Все)'}
        </Typography>
      </>}
      {numSelected > 0 && canBlock && <Button onClick={blockUsers} variant='outlined' color='error' style={{ marginLeft: 'auto' }}>Заблокировать</Button>}
      {numSelected > 0 && canUnblock && <Button  onClick={unblockUsers} variant='outlined' style={{ marginLeft: 'auto' }}>Разблокировать</Button>}
      {numSelected > 0 && !canBlock && !canUnblock && <Typography component="div" variant="subtitle1" style={{ marginLeft: 'auto' }}>Можно управлять только пользователями с одинаковыми статусами</Typography>}
    </RootStyle>
  );
}
