import React from 'react';
import {
  Routes,
  Route
} from "react-router-dom";
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';

// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';

import './custom.css'

export default function App() {
  return (
    <ThemeConfig>
      <GlobalStyles />
      <Layout>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/counter' element={<Counter />} />
          <Route path='/fetch-data' element={<FetchData />} />
        </Routes>
      </Layout>
    </ThemeConfig>
  );
}
