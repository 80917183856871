import React, { Component } from 'react';
import BroadcastForm from './BroadcastForm';
import LoginForm from './LoginForm';
import { Container, Typography } from '@mui/material';

export class Home extends Component {
  static displayName = Home.name;

  constructor(props) {
    super(props);
    this.state = { authenticated: false, users: [], loading: true };
  }

  componentDidMount() {
    this.checkUser();
  }

  render () {
    let contents = this.state.loading
      ? <Typography>Loading...</Typography>
      : this.state.authenticated
        ? <BroadcastForm users={this.state.users} updateUsersState={this.updateUsersState} />
        : <LoginForm />;

    return (
      <Container style={{ paddingTop: '80px' }}>
        {contents}
      </Container>
    );
  }

  async checkUser() {
    const response = await fetch('/account/authenticated', { method: 'POST' });
    const data = await response.json();
    this.setState({ authenticated: data.success, loading: false, users: [] });
    if (data.success) {
      this.setState({ authenticated: data.success, loading: true, users: [] });
      const users = await fetch('/broadcast/listusers', { method: 'POST' })
        .then(r => r.json());
      this.setState({ authenticated: data.success, loading: false, users: users });
    }
  }

  updateUsersState = (userIds, state) => {
    var newUsers = this.state.users.map(u => u);

    for (let userId of userIds) {
      var userIndex = newUsers.findIndex(u => u.id === userId);
      if (userIndex !== -1) {
        newUsers[userIndex].isBlocked = state;
      }
    }
    
    this.setState({ authenticated: this.state.authenticated, loading: false, users: newUsers });
  };
}
